import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel,
    placeArc
} from '../../../common/edliy_utils-circle';
const Boxes = {
    //JXG.Options.line.highlight=false;
    box1: function () {
    //Create the Board
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-14, 12, 2, -4],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    brd1.suspendUpdate();
    // Layering
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['line']=16;
    brd1.options.layer['point']=17;
    brd1.options.layer['glider']=17;
    brd1.options.layer['angle']=18;
    brd1.options.board.minimizeReflow = 'none';
    brd1.options.point.showInfobox =false;
    brd1.options.line.highlight=false;
    brd1.options.image.highlight=false;
//    brd1.options.text.display='internal';
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Work Done by a Force', '(Tap on the wall to move.)');
    //dragMe(brd1, 5, 5);
    //var stone=placeLine(brd1, [-2,0], [3,5], 10, 'grey');
    //
    var pts1 = [-15,0];
    var pts2 = [15,0];
    var pts3 = [15, -5];
    var pts4 = [-15, -5];
    var pts6= [-4, 6];
    var pts7 =[-4, 4];
    var i =0, j=0, k=0, l=0, m=0;
    //placeArrow(brd1, pts6, pts7, 'red');
    //placeGravity(brd1, [-4,6],[-4, 4], 'g');
    var soil =placeImage(brd1,'/assets/floor.svg', -14, -20, 20., 0)
    //var ground =placeRec(brd1, pts1, pts2, pts3, pts4, 'grey');
    //var stone = placeImage(brd1,'/assets/stone.svg', -2, -0.5, 4, 0);
    //var wall = placeImage(brd1,'/assets/wall.svg', -7, -0., 4., 0);
    var wall0 =brd1.create('image', ['/assets/wall.svg',[-4, -0], [1, 1]]);
    var wall01=brd1.create('image', ['/assets/wall.svg',[-4, 1], [1, 1]]);
    var wall02=brd1.create('image', ['/assets/wall.svg',[-4, 2], [1, 1]]);
    var wall03=brd1.create('image', ['/assets/wall.svg',[-4, 3], [1, 1]]);
    var wall2 =brd1.create('image', ['/assets/greenwall.svg',[-8, -0], [1, 1]]);
    var wall21=brd1.create('image', ['/assets/greenwall.svg',[-8, 1], [1, 1]]);
    var wall22=brd1.create('image', ['/assets/greenwall.svg',[-8, 2], [1, 1]]);
    var wall23=brd1.create('image', ['/assets/greenwall.svg',[-8, 3], [1, 1]]);
    var wall3 =brd1.create('image', ['/assets/purplewall.svg',[-13, -0], [1, 1]]);
    var wall31=brd1.create('image', ['/assets/purplewall.svg',[-13, 1], [1, 1]]);
    var wall32=brd1.create('image', ['/assets/purplewall.svg',[-13, 2], [1, 1]]);
    var wall33=brd1.create('image', ['/assets/purplewall.svg',[-13, 3], [1, 1]]);
    ////////////////////////
    var gate1 = brd1.create('image', ['/assets/minuss.svg',[-4, 5], [1, 1]]);
    var gate2 = brd1.create('image', ['/assets/minuss.svg',[-8, 5], [1, 1]]);
    //brd1.create('image', ['/assets/minuss.svg',[-13, 5], [1, 1]]);
    var Pt1 =placePoint(brd1, -2, 1, 2, 'white', 'black');
    var Pt2 =placePoint(brd1, 1, 1, 0, 'white', 'black');
    var Pt3 =placePoint(brd1, -2, 4 , 0, 'white', 'black');
    var track = placeArc(brd1, [()=>Pt1.X(), ()=>Pt1.Y()], [()=>Pt1.X()+3, ()=>Pt1.Y()], [()=>Pt1.X(), ()=>Pt1.Y()+3], 1, 'black');
    placeDash(brd1, [()=>Pt1.X(), 1], [()=>Pt1.X()+4, 1], 1, 'black');
    placeDash(brd1, [()=>Pt1.X(), 1], [()=>Pt1.X(), 5], 1, 'black');
    var Glid = placeGlider(brd1, track, 1, 4, 2, 'red', 'black');
    Glid.setLabel('F');
    Glid.label.setAttribute({CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(22*brd1.canvasWidth/800.)}});
    ////////////////////
    placeAngle(brd1, [()=>Pt1.X()+3, ()=>Pt1.Y()], [()=>Pt1.X(), ()=>Pt1.Y()], [()=>Glid.X(), ()=>Glid.Y()], 1.5, 'black')
    placeArrow(brd1, [()=>Glid.X(), ()=>Glid.Y()],[()=>Pt1.X(), ()=>Pt1.Y()], 2, 'black');
    //placeRuler(brd1, [-4, 4.5],[-8, 4.5], '4 m');
    //placeRuler(brd1, [-8, 4.5],[-13, 4.5], '5 m');
    var box = placeImage(brd1,'/assets/box.svg', ()=>Pt1.X()-1, 0, 2., 0);
    gate1.on('down', function(){
      wall0.setAttribute({visible:false});
      wall01.setAttribute({visible:false});
      wall02.setAttribute({visible:false});
      wall03.setAttribute({visible:false});
      Pt1.moveTo([Pt1.X()-4, 1], 500);
    });
    gate2.on('down', function(){
      wall2.setAttribute({visible:false});
      wall21.setAttribute({visible:false});
      wall22.setAttribute({visible:false});
      wall23.setAttribute({visible:false});
      Pt1.moveTo([Pt1.X()-5, 1], 500);
    });
    hoverMe(brd1, gate1, 'Tap to remove red wall', 0, 80);
    hoverMe(brd1, gate2, 'Tap to remove green wall', 0, 80);
    //var bull = placeImage(brd1,'/assets/bulls.svg', -5.25, -0.65, 4.0, 0);
    //var dog =placeImage(brd1,'/assets/monkey1.svg'1, 0.25, 3, 1.5, 0)
    //var shuffle=placeImage(brd1,'/assets/shuffle.svg', 3, 6, 2., 0)
    //var stone =brd1.create('segment',[[-2.,0],[3, 5]],{strokeColor:'grey', strokeWidth:10, highlight:false});
    //var wallText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the wall on the  plank.');
    //var bullText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the bull on the plank.');
    //var dogText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the monkey on the plank.');
    //var groundText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the floor on the plank');
    //placeText(brd1, 0, 8, '()=>'Total '+ (4-i-j-k-l).valueOf() +' objects remaining');
    /*brd1.create('text', [0., 9, ()=>''+ (m+2-i-j-k-l).valueOf() +' objects remaining !'],{anchorX:'middle', display:'internal', highlight:false, fontSize:function(){return 20*brd1.canvasHeight/800}, cssStyle:'fontFamily:Oswald', fixed:true});
    wallText.setAttribute({visible:false});
    bullText.setAttribute({visible:false});
    dogText.setAttribute({visible:false});
    groundText.setAttribute({visible:false});
///////////////////////////////////////////////////////////

    hoverMe(brd1, wall, 'Wall',0.5*wall.size[0], 0.5*wall.size[1]);
    hoverMe(brd1, dog, 'Monkey Mo', 0, +100);
    hoverMe(brd1, bull, 'Bull Bo', 0.5*bull.size[0], 0.5*bull.size[1]);
    hoverMe(brd1, soil, 'Ground', 0.5*soil.size[0], 0.9*soil.size[1]);
    hoverMe(brd1, shuffle,'Tap to Shuffle!', 0,  -20)
    var Fwall =placeGravity(brd1, [4, 2],[2, 4], 'F_w');
    var Fdog = placeGravity(brd1, [1, 3],[1, 1], 'mg');
    var Fbull = placeGravity(brd1, [-3, 1.],[-1, 1.], 'F_B');
    var Fsoil1 = placeGravity(brd1, [-2, -2],[-2, 0], 'F_n');
    var Fsoil2 = placeGravity(brd1, [-2, 0],[0, 0], 'F_s');
    // Making bull invisible
    Fbull[0].setAttribute({visible:()=>i==1});
    Fbull[1].setAttribute({visible:()=>i==1});
/////////////////////////////////////////////////////////
    bull.setAttribute({visible:()=>i==0});
//    bull.on('down', ()=>i=1; ()=>bull.setAttribute({visible:false}));
    bull.on('down', function(){i=1; bull.setAttribute({visible:false})});
//Making wall invisible
    Fwall[0].setAttribute({visible:()=>j==1});
    Fwall[1].setAttribute({visible:()=>j==1});
//////////////////////////////////////////////
    wall.setAttribute({visible:()=>j==0});
    wall.on('down', ()=>j=1);
///////////////////////////////////////////////////////
    Fdog[0].setAttribute({visible:()=>k==1});
    Fdog[1].setAttribute({visible:()=>k==1});
    dog.setAttribute({visible:()=>k==0});
    //dog.on('down', ()=>k=1);
    dog.on('down', function(){k=1; dog.setAttribute({visible:false})});
//////////////////////////////////////////////////////////////
    Fsoil1[0].setAttribute({visible:()=>l==1});
    Fsoil1[1].setAttribute({visible:()=>l==1});
    Fsoil2[0].setAttribute({visible:()=>l==1});
    Fsoil2[1].setAttribute({visible:()=>l==1});
    soil.setAttribute({visible:()=>l==0});
    soil.on('down', ()=>l=1);
    bull.setAttribute({visible:false});
    dog.setAttribute({visible:false});
//////////////////////////////////////////////////////////////
    shuffle.on('down', function(){
      if(m<2){m+=1}else{m=0}
    //  if(m==0){l=0; j=0; i=2; k=2}
      if(m==0){ i=0;j=0;k=0;l=0;
        bull.setAttribute({visible:false});
        dog.setAttribute({visible:false});
      }
      if(m==1){ i=0;j=0;k=0;l=0;
        bull.setAttribute({visible:false});
        dog.setAttribute({visible:true});
      }
      if(m==2){l=0; j=0; i=0; k=0;
        bull.setAttribute({visible:true});
        dog.setAttribute({visible:true});
      }
    }
  );*/
    brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
