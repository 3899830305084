<template>
  <div>
    <h3>What is Work?</h3>
    <p>
      In our daily life, we refer to various activities as work. For example-when we sit and read a book, we say we
      are doing work. Try pushing a wall, after few minutes you will be tired and you will say that you have done
      work. But scientifically, all these activities are not considered as work.
    </p>
    <h5>Scientific Definition of Work?</h5>
    <p>
      Work is said to be done when the force applied on an object makes the object move (i.e., produces a displacement
      in it). In short, work is said to be done only when an object moves under the influence of the force applied on it.
    </p>
    <p>
      <i>The amount of work done is equal to the product of the force applied and displacement produced in the body in
        the direction of the force applied. </i>It is equal to the dot product of the force applied and the
      displacement. It is given as,
    </p>
    $$W=\vec{F} \cdot \vec{S}= FS \cos{\theta} $$
    <p>
      where \(F\) is the force applied on the object, \(S\) is the displacement, and \(\theta\) is the angle between
      them. Work is a scalar quantity.
    </p>
    <p>
      Work done on a body can be zero, negative or positive depending on the angle between the direction of force
      applied and displacement of the body. For example, when a stone tied to a string whirls around the string,
      no work is said to be done even though tension force in the string applies a centripetal force on the stone.
      It is because the displacement of the stone at all instants is normal to the direction of the tension force
      applied on the stone.
    </p>
    <p>
      S.I unit of work is <i>Newton-meter</i> or <i>joule.</i></b>
      One joule is the work done by a force of 1 newton in displacing the body through a distance of 1 meter in
      its own direction.
    </p>
    <h3>Energy</h3>
    <p>
      Energy is defined as the capacity of a body to do work. If a body can do work, it is said to possess energy.
      Its S.I unit is joule. Whenever work is done, energy transfer takes place.
    </p>
    <p>
      There are different forms of energy, for example solar energy, heat energy, light energy, mechanical energy,
      sound energy, magnetic energy etc. But primarily, there are two forms of energy, kinetic energy and potential
      energy.
    </p>
    <h5>Kinetic energy</h5>
    <p>
      The energy possessed by a body due to its state of motion is called kinetic energy. If a body is moving,
      it possesses kinetic energy. For a body of mass m moving with a velocity \(v\), its kinetic energy \(K\) is
      given as,
    </p>
    $$K=\frac{1}{2}mv^2$$
    <h5>Potential energy</h5>
    <p>
      The energy possessed by a body because of its position, arrangement or state is known as potential energy.
      Potential energy can convert to kinetic energy. Potential energy possessed by a body due to the
      gravitational force applied on it by the earth is known as gravitational potential energy. It is the most
      common form of potential energy that we will study and is equal to \(m \times g \times h\), where \(m\) is
      the mass of the
      body, \(g\) is the acceleration due to gravity and \(h\) is the height of the body.
    </p>
    <h3>Work-Energy Theorem</h3>
    <p>
      We have studied that work done on a body makes the body move and its energy changes. This change in energy
      of the body is the change in its kinetic energy.
      <i> The work-energy theorem states that the work done by the force applied on a body is equal to the change in
        its kinetic energy.</i>
    </p>
    $$W=\Delta K =K_f - K_i$$
    <h5>Derivation of work-energy theorem</h5>
    <p>
      Let us derive the work-energy theorem.
      Consider a body of mass \(m\) moving with an initial velocity \(u\). A force \(F\) is applied on the body which
      produces an acceleration \(a\) and displaces it through a distance \(s\). The final velocity of
      the body is \(v\). Let \(W\) be the work done on the body throughout the entire process.
    </p>
    $$
    \begin{equation} \label{eq2}
    \begin{split}
    \text{Work}&= \text{Force} \times \text{displacement}
    =F \times s \;\;\;\;\;\;  -(i)

    \end{split}
    \end{equation}
    $$

    <p>
      By the third equation of motion, we know \(s=\frac{v^2-u^2}{2a}\). Therefore, replacing \(s\) in equation (i), we
      get
    </p>
    $$
    \begin{equation} \label{eq3}
    \begin{split}
    W&= F \times \left( \frac {v^2-u^2}{2a} \right)
    \\&=ma \times \left( \frac{v^2-u^2}{2a} \right) \;\;\;\;\;\;\;\; \because F=ma
    \\&=\frac{m(v^2-u^2)}{2}

    \\&=\frac{1}{2}mv^2-\frac{1}{2}mu^2
    \\&=K_f-K_i
    \end{split}
    \end{equation}
    $$
    <p>Thus, we have derived that work done on a body is equal to the change in its kinetic energy.</p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
      <v-layout justify-center>
        <h5> Follow this link <a class="icn" href="https://edliy.com/magic"> <i class="fa fa-external-link-square" /></a> to learn how MagicGraphs help students accelerate their learning curve.</h5>
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Work & Energy';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Work & Energy',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
